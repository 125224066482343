// leo dutra
// https://gist.github.com/leodutra/64d90b988535f9e15464165718a49064
import $$ from "./double-dollar";

const hasCoarsePointer = () => window.matchMedia("(pointer: coarse)").matches;
const hasMobileWidth = (maxWidth: number) => window.matchMedia(`(max-width: ${maxWidth}px)`).matches;
const hasMultipleTouchPoints = () => navigator.maxTouchPoints > 1;
const hasTouchEvents = () => "ontouchstart" in document.documentElement;

export const isMobile = (maxWidth: number = 639) => {
  return hasCoarsePointer() && hasMultipleTouchPoints() && hasMobileWidth(maxWidth) && hasTouchEvents();
};
export const loadMobile = () => {
  $$(".js-is-mobile", (el) => el.classList.remove("d-none"));
};
window.addEventListener("load", function () {
  if (isMobile()) loadMobile();
});
